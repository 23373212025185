export const navigationRoutes = {
  navigateToPartnerProgramLogin                                   : '/openbankingtestapp/login',
  navigateToPartnerProgramOtpVerification                         : '/openbankingtestapp/otp-verification',
  navigateToPartnerProgramSetPassword                             : '/openbankingtestapp/set-password',

  navigateToPartnerProgramTermsAndConditions                      : '/openbankingtestapp/terms-and-conditions',
  navigateToDashboard                                             : '/openbankingtestapp/dashboard',
  navigateToAccountDataOverview                                   : '/openbankingtestapp/account-data-overview',
  navigateToAccountDataOverviewSuccess                            : '/openbankingtestapp/account-data-overview-result',

  navigateToAdminLogin                                            : '/admin/login',
  navigateToAdminForgotPassword                                   : '/admin/forgot-password',
  navigateToAdminOtpVerification                                  : '/admin/otp-verification',
  navigateToSetPassword                                           : '/admin/set-password',

  navigateToUnAuthorized                                          : '/admin/unauthorized',

  navigateToAdminHome                                             : '/admin/main/home',
  navigateToApiLogsList                                           : '/admin/main/dashboard/api-logs',
  navigateToListSystemLogs                                        : '/admin/main/dashboard/system-logs',
  navigateToExternalApiLogsView                                   : '/admin/main/dashboard/api-logs/view/',
  navigateToSystemApiLogsView                                     : '/admin/main/dashboard/system-logs-view/',
  navigateToListNotifications                                     : '/admin/main/dashboard/notification-logs',
  navigateToNotificationLogsView                                  : '/admin/main/dashboard/notification-logs/view/',
  navigateToAdminDashboard                                        : '/admin/main/dashboard',

  navigateToUserResponseList                                      : '/admin/main/reports/user-response/list',
  navigateToUserResponseView                                      : '/admin/main/reports/user-response/view/',

  navigateToRegisteredPortalManagementList                        : '/admin/main/user-management/registered-portal-user-management',
  navigateToRegisteredPortalManagementCreate                      : '/admin/main/user-management/registered-portal-user-management/create',
  navigateToRegisteredPortalManagementView                        : '/admin/main/user-management/registered-portal-user-management/view/',
  navigateToRegisteredPortalManagementEdit                        : '/admin/main/user-management/registered-portal-user-management/edit/',
  navigateToRegisteredPortalManagementPermissions                 : '/admin/main/user-management/registered-portal-user-management/user-permissions/',




}
